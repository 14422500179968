/*
    General
*/
#standard_line{
    border-top: 2px solid #444649;
    width: 50px;
}

.justify_text{
    text-align: justify;
}

.padding_line{
    padding: 20px;
}

/*
    Landing Page
*/

html,#landingPage{
    width: 100;
    height: 100vh;
}

#landingPage{
    position: relative;
    margin-bottom: 0;
    text-align: center;
    background-image: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url("../../Components/images/office.jpg");
    /*background-image: linear-gradient(rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.1)), url("../../Components/images/redhouse.jpg");*/
    background-size: cover; /* or contain depending on what you want */
    background-position: center center;
    background-repeat: no-repeat;
}

#landingPage_text{
    color: white;
    text-align: center;
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#landingPage_header{
    font-size: 55px;
    color:white;
    font-family: 'Cormorant SC', serif;
}

#landingPage_subheader{
    font-size: 60px;
    color:gold;
    font-family: 'Great Vibes', cursive;
}

#landingPage_childheader{
    font-size: 30px;
    color:white;
    font-family: 'Dancing Script', cursive;
}


#landingPage_icons{
    font-size: 75px;
    color: white;
}

#landingPage_icons:hover{
    color:gold;
}

#landingPage_button{
    margin:5px;
}

#landingPage_button:hover{
    color:white;
}


/*
    Nav Bar
*/
.navbar {
    opacity: 0.99;
    margin-bottom: 0;
    z-index: 99 !important;
}

#navbar_text:hover{
    color: gold;
}

#navbardrop:hover{
    color:gold;
}

#navbar_dropdown{
    background-color: #212529;
}

#navbar_dropdown_item{
    background-color: #212529;
    color: #c7c7c7;
}
#navbar_dropdown_item:hover{
    color: gold;
}

/*
    Homepage
*/

#white_banner{
    background-color: white;
}
#hompage_testimonial{
    background-color: #d4d8d8;
    background: -webkit-linear-gradient(to right, #d4d8d8, #f2f2f2, #dbdbdb, #d4d8d8);
    background: linear-gradient(to right, #d4d8d8, #f2f2f2, #dbdbdb, #d4d8d8);
}
#white_background_text{
    color:#444649;
}
#homepage_icons{
    color:gold;
    /*font-size: 120px;*/
    font-size: 50px;
    background-color: #fcf9e9;
    padding: 15px;
    border-radius: 30%;
}
#insurance_types{
    padding: 50px;
}

#homepage_icon_text{
    color: #616161;
    /*line-height: 75%;*/
}
#homepage_title_text{
    color: #616161;
    line-height: 85%;
}
#homepage_info_icon{
    color:#c2c6c6;
    font-size: 240px;
}
#homepage_skills{
    background-color: white;
}
#testimonial_quote{
    color: blue;
}
.checked{
    color:orange;
}

.zoom{
    padding: 20px;
    transition: transform .2s; /* Animation */
}

.zoom:hover {
    -ms-transform: scale(1.1); /* IE 9 */
    -webkit-transform: scale(1.1); /* Safari 3-8 */
    transform: scale(1.1); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

#homepage_links:link{
    text-decoration: none;
}
#homepage_links:visited{
    text-decoration: none;
}

.homepage_image{
    padding: 10px;
}

/*About Us*/
.about_sections{
    padding-bottom: 20px;
    padding-top: 20px;
    
}

/*Contact Page*/
.contact_checkbox{
    margin-left: 15px;
    margin-right: 5px;
}

#contact_info{
    text-align: center;
}

.contact_form_div{
    border: 2px solid #c2c6c6;
    border-radius: 5px;
}

.contact_form{
    padding: 15px;
}

/*
    Thanks Page
*/
#thankspage_icons{
    color:gold;
    font-size: 120px;
}

/*
    Error Page
*/
#errorpage_icons{
    color: #ff0000;
    font-size: 120px;
}

/*Meet the Team*/
#card_col{
    padding-bottom: 20px;
}

.card{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

#card_title{
    color:grey;
    font-size:18px;
}

#team_icon{
    color:black;
    padding-bottom: 10px;
}

#team_link:focus{
    outline:none !important;
}

/*
    Insurance Types
*/
#insurance_type_link{
    color: inherit;
    text-decoration: inherit;
}

#insurance_type_link:focus{
    outline:none !important;
}

#insurance_images{
    padding-top: 20px;
    padding-bottom: 20px;
}

.insurance_type_text{
    padding-left: 30px;
    padding-right: 30px;
    text-align: justify;
}

#insurance_type_line{
    padding-bottom: 20px;
}

#insurance_type_bullet{
    color: #616161;
    text-align: left;
}

/*
    Footer
*/
#footer_icon{
    color:#999c9f;
}

footer{
    background-color: #343a40;
}

.footer_name{
    color:white;
}
